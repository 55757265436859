import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { PermissionIdentifierType } from 'src/app/core/services/moveup-api/users/roles/roles.dtos';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
    @Input('hasPermission') permissions!: PermissionIdentifierType | PermissionIdentifierType[];

    constructor(
        private authService: AuthService,
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        const permissionsArray = Array.isArray(this.permissions)
            ? this.permissions
            : [this.permissions];

        this.authService.checkPermissions(permissionsArray).subscribe((hasPermission) => {
            this.viewContainer.clear();
            if (hasPermission) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });
    }
}

<nz-row [nzGutter]="[globals.DEFAULT_MARGIN, globals.DEFAULT_MARGIN]">
    <nz-descriptions
        nz-col
        nzSpan="24"
        nzBordered
        [nzSize]="'small'"
        [nzColumn]="1"
        class="BookmakerDetail"
    >
        <nz-descriptions-item nzTitle="Logo">
            <img
                *ngIf="informations.logo"
                class="BookmakerDetail-logo"
                [src]="informations.logo.url"
                alt="logo"
            />
            <span *ngIf="!informations.logo">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Transparent Logo">
            <img
                *ngIf="informations.transparentLogo"
                class="BookmakerDetail-logo"
                [src]="informations.transparentLogo.url"
                alt="logo"
            />
            <span *ngIf="!informations.transparentLogo">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Type">{{ informations.type }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Name">{{ informations.name || '-' }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Color"
            ><app-color [color]="informations.color"></app-color
        ></nz-descriptions-item>
        <nz-descriptions-item nzTitle="Website URL">
            <a *ngIf="informations.websiteUrl" [href]="informations.websiteUrl" target="_blank">
                <span nz-icon nzType="link" nzTheme="outline"></span>
                {{ informations.websiteUrl }}
            </a>
            <span *ngIf="!informations.websiteUrl">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Website URL Text">
            {{ informations.websiteUrlText || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Prono URL">
            <a
                *ngIf="informations.prognosticUrl"
                [href]="informations.prognosticUrl"
                target="_blank"
            >
                <span nz-icon nzType="link" nzTheme="outline"></span>
                {{ informations.prognosticUrl }}
            </a>
            <span *ngIf="!informations.prognosticUrl">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Overview">
            <span *ngIf="informations.overview" [innerHTML]="informations.overview | nl2br"></span>
            <span *ngIf="!informations.overview">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Casino Rank"
            *ngIf="informations.type === 'casino' || informations.type === 'both'"
        >
            {{ informations.casinoRank || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Sports Rank"
            *ngIf="informations.type === 'sports' || informations.type === 'both'"
        >
            {{ informations.sportsRank || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Players Rating">
            {{ informations.playersRating || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Number of Reviews (Players Rating)">
            {{ informations.numberOfReviews || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Terms & Conditions">
            <span
                *ngIf="informations.termsAndConditions"
                [innerHTML]="informations.termsAndConditions | nl2br"
            ></span>
            <span *ngIf="!informations.termsAndConditions">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Minimum Deposit">
            {{ informations.minimumDeposit || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Minimum Withdrawal">
            {{ informations.minimumWithdrawal || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Withdrawal Delay">
            {{ informations.withdrawalDelay || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Licences">
            <ul *ngIf="informations.licences?.length">
                <li *ngFor="let licence of informations.licences">{{ licence }}</li>
            </ul>
            <span *ngIf="!informations.licences?.length">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Applications">
            <ul *ngIf="informations.applications?.length">
                <li *ngFor="let application of informations.applications">{{ application }}</li>
            </ul>
            <span *ngIf="!informations.applications?.length">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="iOS Url" *ngIf="informations.applications?.includes('ios')">
            <a *ngIf="informations.iosUrl" [href]="informations.iosUrl" target="_blank">
                <span nz-icon nzType="link" nzTheme="outline"></span>
                {{ informations.iosUrl }}
            </a>
            <span *ngIf="!informations.iosUrl">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item
            nzTitle="Android Url"
            *ngIf="informations.applications?.includes('android')"
        >
            <a *ngIf="informations.androidUrl" [href]="informations.androidUrl" target="_blank">
                <span nz-icon nzType="link" nzTheme="outline"></span>
                {{ informations.androidUrl }}
            </a>
            <span *ngIf="!informations.androidUrl">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Rate"> {{ informations.rate || '-' }} </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Payment Methods">
            <ul *ngIf="informations.paymentMethods?.length">
                <li *ngFor="let method of informations.paymentMethods">{{ method.name }}</li>
            </ul>
            <span *ngIf="!informations.paymentMethods?.length">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="websites" nzTitle="Websites">
            <ul *ngIf="websites?.length">
                <li *ngFor="let website of websites">
                    <a target="_blank" [routerLink]="['/websites', 'detail', website.id]">{{
                        website.name
                    }}</a>
                </li>
            </ul>
            <span *ngIf="!websites?.length">-</span>
        </nz-descriptions-item>
    </nz-descriptions>
    <nz-col nzSpan="24" *ngIf="informations.type === 'sports' || informations.type === 'both'">
        <ng-container
            *ngTemplateOutlet="
                typeSpecificInformations;
                context: {
                    title: 'Sports',
                    prosAndCons: informations.sportsProsAndCons,
                    bonus: informations.sportsBonus
                }
            "
        ></ng-container>
    </nz-col>
    <nz-col nzSpan="24" *ngIf="informations.type === 'casino' || informations.type === 'both'">
        <ng-container
            *ngTemplateOutlet="
                typeSpecificInformations;
                context: {
                    title: 'Casino',
                    prosAndCons: informations.casinoProsAndCons,
                    bonus: informations.casinoBonus
                }
            "
        ></ng-container>
    </nz-col>
    <!-- Sticky CTA -->
    <nz-descriptions
        nz-col
        nzSpan="24"
        nzBordered
        [nzSize]="'small'"
        [nzColumn]="1"
        class="BookmakerDetail"
        nzTitle="Sticky CTA"
        *ngIf="informations.stickyCta as stickyCta"
    >
        <nz-descriptions-item nzTitle="Logo">
            <img
                *ngIf="stickyCta.logo"
                class="BookmakerDetail-logo"
                [src]="stickyCta.logo.url"
                alt="logo"
            />
            <span *ngIf="!stickyCta.logo">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Button Color">
            <app-color [color]="stickyCta.buttonColor"></app-color>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Button Text Color">
            <app-color [color]="stickyCta.buttonTextColor"></app-color>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Background Color">
            <app-color [color]="stickyCta.backgroundColor"></app-color>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Title Color">
            <app-color [color]="stickyCta.titleColor"></app-color>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Close Button Color">
            <app-color [color]="stickyCta.closeButtonColor"></app-color>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="T&Cs Color">
            <app-color [color]="stickyCta.termsAndConditionsColor"></app-color>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Title Text">
            <div class="BookmakerDetail-subtitle">Bookmaker Apostas</div>
            {{ stickyCta.apostas.titleText || '-' }}
            <div class="BookmakerDetail-subtitle">Bookmaker App</div>
            {{ stickyCta.app.titleText || '-' }}
            <div class="BookmakerDetail-subtitle">Bookmaker Code</div>
            {{ stickyCta.code.titleText || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Button Text">
            <div class="BookmakerDetail-subtitle">Bookmaker Apostas</div>
            {{ stickyCta.apostas.buttonText || '-' }}
            <div class="BookmakerDetail-subtitle">Bookmaker App</div>
            {{ stickyCta.app.buttonText || '-' }}
            <div class="BookmakerDetail-subtitle">Bookmaker Code</div>
            {{ stickyCta.code.buttonText || '-' }}
        </nz-descriptions-item>
    </nz-descriptions>
</nz-row>

<ng-template
    #typeSpecificInformations
    let-title="title"
    let-prosAndCons="prosAndCons"
    let-bonus="bonus"
>
    <nz-descriptions
        nzBordered
        [nzSize]="'small'"
        [nzColumn]="1"
        class="BookmakerDetail"
        [nzTitle]="title"
    >
        <nz-descriptions-item nzTitle="Pros">
            <span *ngIf="prosAndCons.pros" [innerHTML]="prosAndCons.pros | nl2br"></span>
            <span *ngIf="!prosAndCons.pros">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Cons">
            <span *ngIf="prosAndCons.cons" [innerHTML]="prosAndCons.cons | nl2br"></span>
            <span *ngIf="!prosAndCons.cons">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Bonus Amount">
            {{ bonus.bonusAmount || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Bonus Code">
            {{ bonus.bonusCode || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Bonus Name (for table shortcode)">
            {{ bonus.bonusName || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Bonus Catchline">
            {{ bonus.bonusCatchline || '-' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Bonus URL">
            <a *ngIf="bonus.bonusUrl" [href]="bonus.bonusUrl" target="_blank">
                <span nz-icon nzType="link" nzTheme="outline"></span>
                {{ bonus.bonusUrl }}
            </a>
            <span *ngIf="!bonus.bonusUrl">-</span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Bonus Detail">
            <span *ngIf="bonus.bonusDetail" [innerHTML]="bonus.bonusDetail | nl2br"></span>
            <span *ngIf="!bonus.bonusDetail">-</span>
        </nz-descriptions-item>
    </nz-descriptions>
</ng-template>
